import React, { useEffect, useState } from "react"
import "./SocialItem.scss"
import LearnMoreBtn from "../learn-more-btn/LearnMoreBtn"
import { isBrowser } from "../../services/Utils"

const SocialItem = ({ social, title, description, image, id }) => {
  const [imgWidth, setImgWidth] = useState()

  if (isBrowser()) {
    const img = new Image()
    img.onload = function () {
      setImgWidth(this.width)
    }
    img.src = image
  }

  return (
    <>
      {imgWidth > 1380 ? (
        <>
          {social && social.detailsUrl ? (
            <a href={social.detailsUrl}>
              <div className="zero-left-padding social-item row mt-5">
                <div
                  className="image-wrapper col-lg-12 d-flex align-items-center justify-content-center"
                  style={{
                    height: "480px",
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    borderRadius: "24px",
                  }}
                ></div>
                <div className="zero-left-padding title col-lg-12 mt-4 mb-3">{title}</div>
                <div
                  className="zero-left-padding description col mb-4"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <LearnMoreBtn props={social} />
              </div>
            </a>
          ) : (
            <div className="zero-left-padding social-item row mt-5">
              <div
                className="image-wrapper col-lg-12 d-flex align-items-center justify-content-center "
                style={{
                  height: "480px",

                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  borderRadius: "24px",
                }}
              ></div>
              <div className="title zero-left-padding col-lg-12 mt-4 mb-3">{title}</div>
              <div
                className="zero-left-padding description col mb-4"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <LearnMoreBtn props={social} />
            </div>
          )}
        </>
      ) : (
        <>
          {social && social.detailsUrl ? (
            <a href={social.detailsUrl}>
              <div className="zero-left-padding social-item row mt-5">
                <div className="image-wrapper collg-12 d-flex align-items-center justify-content-center">
                  <img id={`img${id}`} alt="image" src={image} />
                </div>
                <div className="title zero-left-padding col-lg-12 mt-4 mb-3">{title}</div>
                <div
                  className="zero-left-padding description col mb-4"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <LearnMoreBtn props={social} />
              </div>
            </a>
          ) : (
            <div className="zero-left-padding social-item row mt-5">
              <div className="image-wrapper col-lg-12 d-flex align-items-center justify-content-center ">
                <img id={`img${id}`} alt="image" src={image} />
              </div>
              <div className="title zero-left-padding col-lg-12 mt-4 mb-3">{title}</div>
              <div
                className="description zero-left-padding col mb-4"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <LearnMoreBtn props={social} />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default SocialItem
