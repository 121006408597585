import React from "react"
import "../scss/SocialPage.scss"
import Seo from "../components/seo/seo"

import SocialItem from "../shared/social-item/SocialItem"
import { getImageUrl } from "../services/Utils"

import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const Social = props => {
  const staticContents = props.pageContext.staticContent
  const socialPrograms = props.pageContext.social
  const menu = props.pageContext.menu
  const staticHeaderPage = props.pageContext.staticHeaderPage

  return (
    <div>
      <div className="container socials-page">
        <Header
          menu={menu}
          siteTitle={staticContents["<title>"]}
          contents={staticHeaderPage}
          displayLogoLinks={false}
        />
        <Seo
          keywordsContent={staticContents["<meta> keywords"]}
          descriptionContent={staticContents["<meta> description"]}
          title={staticContents["<title>"]}
        />
        <div className="row mb-6 justify-content-center">
          <div className="mt-4 zero-left-padding">
            <h1 className="col-lg-11 mb-3">{staticContents["Page Header"]}</h1>
          </div>
          <div className="zero-left-padding">
            <div className="description col">
              {staticContents["Page Header Details"]}
            </div>
          </div>
          {socialPrograms && socialPrograms.length
            ? socialPrograms.map((socialItem, index) => {
              return (
                <SocialItem
                  social={socialItem}
                  title={socialItem.title}
                  description={socialItem.description}
                  image={getImageUrl(socialItem.imageUrl)}
                  id={index}
                />
              )
            })
            : null}
        </div>
      </div>
      <Footer recaptchaSettings={props.pageContext.recaptchaSettings} staticContactsForm={props.pageContext.staticContactsForm} staticContactsPage={props.pageContext.staticContactsPage} />
    </div>
  )
}

export default Social
